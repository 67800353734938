section.content-section {
	header.section-title {
		hgroup {
			.title,
			.subtitle {
				line-height: 1;
			}

			.title {
				font-size: 3.5rem;
				color: $blue;
				margin: 0;
			}

			.subtitle {
				font-size: 1.5rem;
				margin: 0.25em 0 0;
				font-family: $sans;
				//text-transform: uppercase;
				font-weight: 800;
			}

			+ article {
				margin-top: 1em;
			}
		}
	}

	article {
		p,
		p + a {
			margin-top: ($section-padding / 4);
		}
	}

	&[class*="text-accent-"] {
		&.text-accent-purple {
			hgroup {
				.title {
					color: $purple;
				}
			}
		}
	}

	&[class*="section-bg-"] {
		&.section-bg-blue {
			header.section-title {
				hgroup {
					.title {
						color: $blue;
					}
				}
			}
		}

		&.section-bg-green {
			header.section-title {
				hgroup {
					.title {
						color: $green;
					}
				}
			}
		}

		&.section-bg-purple {
			header.section-title {
				hgroup {
					.title {
						color: $purple;
					}
				}
			}
		}
	}
}
