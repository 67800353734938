a.btn,
input.gform_button {
	@include hover;
	display: inline-block;
	padding: 18px 45px;
	line-height: 1;
	background-color: $blue;
	color: $white;
	font-weight: 800;
	font-size: 0.9rem;
	text-transform: uppercase;
	min-width: 250px;
	max-width: 100%;
	text-align: center;
	position: relative;
	border-radius: 3px;

	@include media-breakpoint-down(md) {
		display: block;
		min-width: 0;
		padding-left: 25px;
		padding-right: 25px;
	}

	&.small {
		padding: 12px 25px;
		min-width: 0;
	}

	&.blue,
	&.page {
		background-color: $blue;
	}

	&.green,
	&.post {
		background-color: $green;
	}

	&.purple,
	&.event {
		background-color: $purple;
	}

	&.orange {
		background-color: $orange;
	}
}
