body {
	.gform_wrapper {
		display: block !important;

		form {
			.gform_body {
				.gform_fields {
					.gfield {
						label.gfield_label {
						}

						.ginput_container {
							textarea,
							input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
							}
						}
					}
				}
			}

			.gform_footer {
				.gform_button {
				}
			}
		}
	}
}
