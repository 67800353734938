.event-filters {
	border-bottom: 1px dotted darken(white, 15);
	margin-bottom: 2rem;

	// List of "Type" Items
	&__types {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;
		margin-bottom: 0.5rem;

		&__title {
			font-weight: bold;
			text-transform: uppercase;
			margin-right: 0.5rem;
			margin-bottom: 0.25rem;
			font-size: 0.75em;
			display: block;
			width: 100%;
			color: rgba(51, 50, 57, 0.35);
			line-height: 1;
		}
	}

	// Single "Type" Item
	&__type {
		margin-right: 0.375rem;
		font-size: 0.8rem;
		text-transform: uppercase;

		&__label {
			margin: 0;
		}

		&__name {
			cursor: pointer;
			display: flex;
			align-items: center;
			opacity: 0.45;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			line-height: 1;
			padding: 0.35rem;
			padding-right: 0.5rem;
			border: 1px solid rgba(51, 50, 57, 0.2);
			background-color: #fff;
			border-radius: 100px;

			&:hover {
				border-color: #333239;
			}
		}

		&__key {
			height: 0.75rem;
			width: 0.75rem;
			display: inline-block;
			border-radius: 100%;
			margin-right: 0.25rem;
			position: relative;
			background-color: #a32b42;
		}

		&__checkbox {
			opacity: 0;
			text-indent: -9999px;
			position: absolute;
		}
	}
}

.event-filters__type__checkbox:checked + .event-filters__type__name {
	opacity: 1;
}
