section.content-section.callouts {
	padding: 0;
	width: 100%;
	overflow: hidden;

	.callout-box {
		article.callout {
			@include hover;
			background-color: darken($white, 5);
			padding: 1.5em;
			position: relative;
			height: 100%;

			h3 {
				font-family: $sans;
				font-weight: 800;
				text-transform: uppercase;
				margin: 0;
			}

			p {
				margin: 0;
				line-height: 1;
			}

			i {
				font-size: 3rem;
				line-height: 1;
			}

			svg {
				width: 50px;
				max-height: 45px;
				display: inline-block;
				vertical-align: middle;

				path {
					fill: $white;
				}
			}

			a.callout-link {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
			}

			&:hover {
				h3 {
					text-decoration: underline;
				}
			}
		}

		&.blue,
		&.green,
		&.purple {
			color: $white;
		}

		&.blue {
			article.callout,
			&:before {
				background-color: $blue;
			}
		}

		&.green {
			article.callout,
			&:before {
				background-color: $green;
			}
		}

		&.purple {
			article.callout,
			&:before {
				background-color: $purple;
			}
		}

		&:first-child {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 100%;
				width: 9999%;

				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}

		&:last-child {
			&:before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 100%;
				width: 9999%;

				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}
	}

	+ section.content-section-callouts {
		border-top: 5px solid $white;
	}
}
