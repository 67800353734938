section.content-section.upcoming-events {
	position: relative;

	figure {
		border-radius: 100%;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			width: 50%;
			margin: 0 auto 1em;
		}
	}

	article.event-details {
		padding-left: $section-padding / 2;

		@include media-breakpoint-down(md) {
			padding: 0 $section-padding-md;
			text-align: center;
		}

		header {
			margin-bottom: 1.35em;
		}

		h4.event-title,
		h5.event-meta {
			color: $blue;
			font-family: $sans;
			font-weight: 800;
		}

		h4.event-title {
			text-transform: uppercase;
			font-size: 1.25rem;
			margin-bottom: 0.25em;
		}

		h5.event-meta {
			font-size: 0.9rem;
			margin-bottom: 0;
		}

		p {
			&.event-summary {
				margin-top: 1em;
			}

			&.event-buttons {
				a.btn {
					+ a.btn {
						margin-left: 1em;

						@include media-breakpoint-down(md) {
							margin: 0.5em 0 0;
						}
					}
				}
			}
		}
	}

	button.arrow {
		$arrow-size: 70px;
		$arrow-offset: 1vw;
		position: absolute;
		top: 50%;
		margin-top: -($arrow-size / 2);
		background-color: transparent;
		padding: 0;
		line-height: 1;
		border: none;
		font-size: $arrow-size;
		opacity: 0.25;
		cursor: pointer;

		&.next {
			right: $arrow-offset;
		}

		&.prev {
			left: $arrow-offset;
		}

		&:hover {
			opacity: 0.75;
		}
	}
}
