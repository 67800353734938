section.content-section.accordion-content {
	text-align: center;

	header.section-title {
		article {
			padding: 0 6vw;

			@include media-breakpoint-down(md) {
				padding: 0;
			}
		}
	}
}
