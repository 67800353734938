section.content-section.loop {
	ul.loop-list {
		li.loop-item {
			margin: 25px 0 0;
			padding: 30px 30px 25px;
			background-color: darken(#fff, 5);

			h2 {
				font-size: 2rem;
				margin-bottom: 0.5em;

				a {
					color: $orange;
				}
			}

			.entry {
				margin-bottom: 1em;
			}

			&.event {
				h2 {
					a {
						color: $purple;
					}
				}
			}

			&.page {
				h2 {
					a {
						color: $blue;
					}
				}
			}

			&.post {
				h2 {
					a {
						color: $green;
					}
				}
			}
		}
	}

	nav.post-nav {
		padding: 2em 0;

		.next-posts {
			display: inline-block;
		}

		.prev-posts {
			float: right;
		}
	}

	&.archive {
		&.events {
			figure#calendar {
				table {
					border-collapse: collapse;
					width: 100%;
					margin-bottom: 1em;

					thead {
						tr {
							th {
								text-align: center;
								text-transform: uppercase;
								padding: 0.5em 0;
								font-size: 1.1rem;
								font-weight: bold;
							}
						}
					}

					tbody {
						table {
							margin: 0;
						}

						tr {
							td {
								text-align: center;
								font-size: 1.2rem;
								font-weight: bold;
								color: $purple;

								.fc-event-container {
									tr {
										display: block;

										td {
											display: block;
										}
									}
								}
							}
						}
					}
				}

				.fc-list-table {
					margin: 0;

					.fc-list-heading {
						display: none;
					}

					td {
						border: none;
						padding: 0;
					}
				}

				.event-item {
					padding: 1rem;
					background-color: darken($white, 6);
					margin-bottom: 1em;
					font-size: 1.05rem;
					margin: 0.25rem;
					border-radius: 5px;
					border-top-width: 5px;
					border-top-style: solid;
					border-top-color: transparent;

					@media (max-width: 768px) {
						font-size: 1rem;
						padding: 15px;
						margin-bottom: 0;
					}

					hgroup {
						word-wrap: break-word;

						h2 {
							font-size: 1.2rem;
							line-height: 1.2em;
							margin: 0;
						}
					}

					.date-box {
						width: 75px;
						text-align: center;
						background-color: $white;

						span {
							display: block;
							font-size: 1rem;
							text-transform: uppercase;
							line-height: 1;

							&.month {
								background-color: $purple;
								padding: 5px 0;
								color: $white;
							}

							&.day {
								font-size: 2.6rem;
								font-weight: 800;
								padding: 10px 0;
							}
						}
					}

					.summary {
						display: none;
						margin-top: 0.5em;

						p {
							margin: 0;

							+ p {
								margin-top: 1em;
							}
						}
					}

					.summary-toggle {
						font-size: 0.9rem;
						cursor: pointer;
						color: $orange;

						&:before {
							content: "[+] More ";
						}

						&.open {
							&:before {
								content: "[-] Less ";
							}
						}
					}
				}

				.fc-toolbar {
					* {
						vertical-align: middle;
					}

					h2 {
						display: inline;
					}

					button.fc-button {
						position: relative;
						top: -2px;
					}
				}

				.fc-list-view {
					border: none;
				}

				.fc-list-empty {
					padding: 25px 0;
				}

				.fc-head {
					table {
						margin: 0;
					}
				}
			}
		}
	}

	&.search {
		form.searchform {
			input[type="text"] {
				width: 100%;
				padding: 15px;
				font-size: 1.2rem;
				line-height: 1;
			}
		}
	}
}
