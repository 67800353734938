section.hero {
	$header-height: 116px;
	text-align: center;
	color: $white;

	hgroup {
		text-shadow: 0 0 15px rgba($black, 0.65);

		h1,
		h2 {
			line-height: 1;
		}

		h1 {
			font-size: 4.5rem;
			margin: 0;
		}

		h2 {
			font-size: 1.6rem;
			font-family: $sans;
			font-weight: 800;
			margin: 0.5em 0 0;
		}
	}

	&.page {
		.slide {
			//padding-top: $header-height;
			background-size: cover;
			background-position: center center;
			height: 450px;

			hgroup {
				padding: 12vh 0 10vh 0;
			}
		}

		aside.event-meta {
			padding: 1em 0;
			background-color: $text-color;
			color: $white;
			text-align: left;

			h4 {
				font-size: 1.6rem;
				margin: 0;

				a {
					color: lighten($green, 3);

					&:hover {
						text-decoration: underline;
					}
				}
			}

			i {
				margin-right: 5px;
			}
		}
	}

	&.home {
		height: 75vh;
		position: relative;

		@include media-breakpoint-down(md) {
			height: auto;
		}

		button.arrow {
			$arrow-size: 70px;
			$arrow-offset: 1vw;
			position: absolute;
			top: 50%;
			margin-top: -($arrow-size / 2);
			background-color: transparent;
			padding: 0;
			line-height: 1;
			border: none;
			font-size: $arrow-size;
			opacity: 0.25;
			color: $white;
			cursor: pointer;

			&.next {
				right: $arrow-offset;
			}

			&.prev {
				left: $arrow-offset;
			}

			&:hover {
				opacity: 0.75;
			}
		}

		.slides {
			height: 100%;

			figure.slide {
				height: 100%;
				background-size: cover;
				background-position: center center;
				padding-top: 116px;

				@include media-breakpoint-down(md) {
					padding-top: 80px;
				}

				figcaption {
					text-align: center;
					color: $white;
					background-image: url("assets/dist/images/paw-print-watermark-white.png");
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					padding: 15vh 0;

					@include media-breakpoint-down(md) {
						padding: 10vh 5vw;
					}

					hgroup {
						margin-bottom: 3em;

						h1 {
							font-size: 6rem;

							@include media-breakpoint-down(lg) {
								font-size: 5rem;
							}

							@include media-breakpoint-down(md) {
								font-size: 4rem;
							}
						}

						h2 {
							font-size: 2.1rem;
							line-height: 1.5em;

							@include media-breakpoint-down(md) {
								font-size: 1.6rem;
							}
						}
					}
				}
			}
		}

		.slick-list,
		.slick-track {
			height: 100%;
		}
	}
}
