/*** Footer ***/

footer.main {
	background-color: darken($white, 5);

	@mixin column-header() {
		color: $blue;
		text-transform: uppercase;
		font-family: $sans;
		font-weight: 800;
		font-size: 1rem;
		margin: 0 0 0.5em;
		display: block;
	}

	@mixin column-link() {
		color: $text-color;
		padding: 8px 0;
		display: block;
		font-size: 0.75rem;
		line-height: 1.6em;
	}

	section.top {
		padding: 100px 0 50px;

		@include media-breakpoint-down(md) {
			padding: 50px 25px;
		}

		a.logo {
			width: 200px;
			margin-top: -25px;
			display: block;
			max-width: 100%;

			svg {
				display: block;

				path {
					&.st0 {
						fill: $blue;
					}

					&.st1 {
						fill: $green;
					}
				}
			}
		}

		p.mailing-address,
		ul.links {
			font-size: 0.9rem;
		}

		p.mailing-address {
			margin: 1.5em 0;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			&.links {
				margin-bottom: 1.5em;

				li {
					color: $green;
					padding: 3px 0;

					*,
					& {
						vertical-align: middle;
					}

					a {
						color: $green;
						text-transform: uppercase;
						font-weight: 800;
					}

					i {
						margin-right: 8px;
						font-size: 1.1rem;
						position: relative;
						top: 2px;
					}
				}
			}

			&.social-media-links {
				$icon-size: 22px;

				li {
					float: left;

					a {
						background-color: darken($white, 35);
						display: block;
						width: $icon-size;
						line-height: $icon-size;
						text-align: center;
						border-radius: 100%;

						i {
							color: $white;
							font-size: $icon-size - 8;
							line-height: 0;
							position: relative;
						}
					}

					+ li {
						margin-left: 0.75em;
					}
				}
			}
		}

		.widget {
			text-transform: uppercase;
			font-weight: 600;

			@include media-breakpoint-down(md) {
				margin-top: 2em;
			}

			p {
				@include column-link;
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				&.menu {
					list-style: none;

					> li {
						> a {
							@include column-header();

							&:hover {
								text-decoration: underline;
							}
						}

						ul.sub-menu {
							li {
								a {
									@include column-link();

									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}

				&.hours {
					li {
						@include column-link();
					}
				}
			}

			h3 {
				@include column-header();
			}
		}
	}

	aside.copyright {
		text-align: center;
		border-top: 2px solid darken($white, 20);
		padding: 1.5em 0;
		text-transform: uppercase;
		font-size: 0.8rem;
		line-height: 1;

		@include media-breakpoint-down(md) {
			line-height: 1.7em;
		}

		&,
		a {
			color: darken($white, 35);
		}

		a {
			text-decoration: underline;
		}

		span.bullet {
			padding: 0 10px;
		}
	}
}
