/*** Content ***/

main {
	p,
	ol,
	ul,
	blockquote {
		font-size: 1.1rem;
		line-height: 1.8em;
	}

	p {
		margin: 0;

		+ p {
			margin-top: 1.5em;
		}
	}

	table {
		background-color: $white;
		margin: 1em 0;

		tr {
			td,
			th {
				padding: 15px;
				line-height: 1.4em;
			}

			th {
				background-color: $text-color;
				color: $white;
				border-color: darken($text-color, 20);
			}

			td {
				border-color: darken($white, 20);
				border-bottom: 1px solid darken($white, 20);

				&:first-child {
					border-left: 1px solid darken($white, 20);
				}

				&:last-child {
					border-right: 1px solid darken($white, 20);
				}
			}
		}
	}
}
