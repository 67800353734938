section.content-section.split-content {
	position: relative;

	@include media-breakpoint-down(md) {
		padding-top: 0;
	}

	aside.sidebar {
		position: absolute;
		top: 0;
		left: 50%;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		//max-height: 150vh;

		@include media-breakpoint-down(md) {
			position: static;
			height: 30vh;
		}
	}

	header.section-title {
		text-align: center;
		position: relative;
		z-index: 1;

		@include media-breakpoint-down(md) {
			padding: $section-padding-md 0 0 !important;
		}
	}

	.container {
		position: relative;
		z-index: 10;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 50%;
		bottom: 0;
		left: 0;
		background-image: url("assets/dist/images/paw-print-watermark-1.png");
		background-repeat: no-repeat;
		background-size: auto 90%;
		background-position: left 20px;
		z-index: 0;
	}

	&.text-align-left {
		header.section-title {
			text-align: left;
		}
	}

	&.text-align-right {
		header.section-title {
			text-align: right;
		}
	}

	&.sidebar-position-right {
		header.section-title {
			padding-right: $section-padding / 2;
		}
	}

	&.sidebar-position-left {
		aside.sidebar {
			left: 0;
			right: 50%;
		}

		header.section-title {
			padding-left: $section-padding / 2;
		}

		&:after {
			left: 50%;
			right: 0;
			background-image: url("assets/dist/images/paw-print-watermark-2.png");
			background-position: right 20px;
		}
	}
}
