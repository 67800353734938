/*** Fonts ***/
@import url("https://fonts.googleapis.com/css?family=Bubblegum+Sans|Nunito+Sans:300,400,600,700,800");
$sans: "Nunito Sans", sans-serif;
$handwritten: "Bubblegum Sans", sans-serif;
$primary-font: $sans;
$header-font: $handwritten;

/*** Colors ***/
$blue: #4999b6;
$purple: #a16dac;
$green: #83ae60;
$grey: #676767;
$white: #fff;
$black: #000;
$background-color: $white;
$primary-color: $blue;
$secondary-color: $purple;
$text-color: $grey;

/*** Template Dimensions ***/

/*** Resetting Bootstrap Breakpoints ***/
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);
