section.content-section.testimonial-slider {
	text-align: center;
	position: relative;

	button.arrow {
		$arrow-size: 70px;
		$arrow-offset: 1vw;
		position: absolute;
		top: 50%;
		margin-top: -($arrow-size / 2);
		background-color: transparent;
		padding: 0;
		line-height: 1;
		border: none;
		font-size: $arrow-size;
		opacity: 0.25;
		cursor: pointer;

		&.next {
			right: $arrow-offset;
		}

		&.prev {
			left: $arrow-offset;
		}

		&:hover {
			opacity: 0.75;
		}
	}

	article {
		margin: 2em;

		p,
		ul,
		ol,
		blockquote {
			margin: 0;
			font-size: 1.1rem;
		}
	}

	footer {
		p.author {
			font-weight: 800;
		}
	}

	&[class*="text-accent-"] {
		&.text-accent-purple {
			footer {
				p.author {
					color: $purple;
				}
			}
		}
	}
}
