section.content-section.anchor-link-navigation {
	padding: 0;
	//background-color: darken($text-color, 15);
	background-color: $blue;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 10px 0;
	}

	.anchor-link {
		border-right: 1px solid rgba($black, 0.2);

		@include media-breakpoint-down(lg) {
			font-size: 0.9rem;
			border: none !important;
		}

		a {
			color: $white;
			text-transform: uppercase;
			display: block;
			padding: 15px 25px;
			transition: background-color 0.4s ease;

			@include media-breakpoint-down(md) {
				padding: 10px;
			}

			&:hover {
				background-color: darken($blue, 10);
			}
		}

		&:first-child {
			border-left: 1px solid rgba($black, 0.2);
		}
	}
}
