section.content-section.logo-slider {
	text-align: center;

	header.section-title {
		margin-bottom: 2em;

		hgroup {
			.title {
				color: $green;
			}
		}
	}

	.logo-slides {
		.logo-slide {
			text-align: center;

			img {
				max-width: 160px;
				max-height: 75px;
				margin: 0 ($section-padding / 3);

				@include media-breakpoint-down(sm) {
					margin: 10px auto;
				}
			}

			&.logos-medium {
				img {
					max-width: 250px;
					max-height: 125px;
				}
			}

			&.logos-large {
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
}
