$section-padding: 100px;
$section-padding-md: 60px;

section.content-section {
	padding: $section-padding 0;

	@include media-breakpoint-down(md) {
		padding: $section-padding-md 0;
	}

	&[class*="section-bg-"] {
		&.section-bg-blue {
			background-color: lighten($blue, 40);
		}

		&.section-bg-green {
			background-color: lighten($green, 40);
		}

		&.section-bg-purple {
			background-color: lighten($purple, 40);
		}
	}
}

@import "parts/parts";

@import "callouts";
@import "centered-content";
@import "split-content";
@import "upcoming-events";
@import "logo-slider";
@import "testimonial-slider";
@import "loop";
@import "anchor-link-navigation";
@import "accordion";
@import "instagram-feed";
