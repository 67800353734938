/*** Global App Styles ***/

body {
	font-family: $primary-font;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $header-font;
		font-weight: normal;
	}

	p,
	ol,
	ul,
	blockquote {
	}

	a {
		&.back-to-top {
			$icon-size: 70px;
			$icon-offset: 2vw;
			height: $icon-size;
			width: $icon-size;
			line-height: $icon-size;
			position: fixed;
			bottom: 0;
			right: $icon-offset;
			border-radius: 100%;
			background-color: rgba($black, 0.8);
			text-align: center;
			color: $white;
			opacity: 0;
			transition: all 0.4s ease;
			margin-bottom: -25px;
			z-index: 9999;

			@include media-breakpoint-down(md) {
				height: $icon-size / 1.25;
				width: $icon-size / 1.25;
				line-height: $icon-size / 1.25;
			}

			i {
				line-height: 1;
				font-size: $icon-size / 3;

				@include media-breakpoint-down(md) {
					font-size: $icon-size / 4;
				}
			}

			&.visible {
				opacity: 1;
				margin-bottom: 0;
				bottom: $icon-offset;
			}
		}
	}

	i[class*="icon-"] {
		display: inline-block;
	}

	figure.iframe-box {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		width: 100vw;
		margin: 0 -15px;
	}

	.to-animate {
		opacity: 0;
	}
}
