/*** Header ***/

header.main {
	position: absolute;
	//top: 0;
	left: 0;
	right: 0;
	z-index: 10000;

	a {
		color: $white;

		i {
			font-size: 1.25rem;
		}

		&:hover {
			color: saturate($secondary-color, 25);
		}

		&.logo {
			padding: 0.75em 0 0;
			display: block;

			img,
			svg {
				width: 200px;

				@include media-breakpoint-down(lg) {
					width: 150px;
				}

				@include media-breakpoint-down(sm) {
					width: 100px;
				}

				path {
					fill: $white;
					transition: all 0.4s ease;
				}
			}

			&:hover {
				svg {
					filter: drop-shadow(0 0 10px rgba($black, 0.5));

					path {
						&.st0 {
							fill: saturate($blue, 20);
						}

						&.st1 {
							fill: saturate($green, 20);
						}
					}
				}
			}
		}
	}

	nav {
		float: right;

		a {
			&.mobile-toggle-button {
				color: $white;
				text-transform: uppercase;
				font-weight: 800;
				font-size: 1.1rem;
				padding: 5px 15px;

				@include media-breakpoint-down(md) {
					display: block;
				}

				@include media-breakpoint-down(sm) {
					padding: 5px;
				}

				i {
					margin-right: 5px;

					@include media-breakpoint-down(sm) {
						margin: 0;
					}
				}

				label {
					margin: 0;

					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
			}

			&.mobile-close-button {
				$button-size: 40px;
				display: none;
				position: fixed;
				z-index: 9999999;
				top: 12px;
				right: 12px;
				width: $button-size;
				line-height: $button-size;
				text-align: center;
				color: $text-color;
				background-color: $white;
				box-shadow: 0 0 10px rgba($black, 0.25);

				i {
					position: relative;
					top: 2px;
				}
			}
		}

		ul.menu {
			list-style: none;
			margin: 0;
			padding: 0;
			transition: all 0.5s ease;

			@include media-breakpoint-down(md) {
				opacity: 0;
				visibility: hidden;
				position: fixed;
				background-color: rgba($white, 0.95);
				right: 0;
				top: 0;
				bottom: 0;
				left: 0;
				padding: 4em 0;
				z-index: 999999;
				overflow: scroll;
				vertical-align: middle;
			}

			> li {
				float: left;
				text-transform: uppercase;

				@include media-breakpoint-down(md) {
					float: none;
				}

				> a {
					font-weight: 800;
					display: block;
					padding: 10px 25px;
					text-shadow: 0 0 15px rgba($black, 0.65);

					@include media-breakpoint-down(lg) {
						padding: 10px 15px;
					}

					@include media-breakpoint-down(md) {
						text-shadow: none;
						color: $text-color;
						white-space: nowrap;
						padding: 8px 15vw;
						font-size: 1.5rem;
						font-weight: normal;
						background-color: $purple;
						color: $white;
					}
				}

				&#menu-item-67 {
					ul.sub-menu {
						//display: block;
					}
				}

				> ul.sub-menu {
					display: none;
					position: absolute;
					list-style: none;
					margin: 0;
					padding: 0;
					column-count: 3;
					background-color: $white;
					padding: 1em;
					z-index: 10000;
					top: 100%;
					left: 15px;
					right: 15px;

					@include media-breakpoint-down(md) {
						display: block;
						position: static;
						column-count: 1;
						background-color: transparent;
						padding: 1em 15vw;
					}

					a {
						color: $text-color;
					}

					> li {
						//display: inline;
						margin-bottom: 10px;
						break-inside: avoid;

						@include media-breakpoint-down(md) {
							margin-bottom: 15px;
						}

						> a {
							font-weight: 800;
						}

						a {
							&:hover {
								color: $purple;
							}
						}

						ul.sub-menu {
							//display: none;
							list-style: none;
							margin: 0;
							padding: 0;
							text-transform: none;

							@include media-breakpoint-down(md) {
								margin: 0;
							}

							li {
								display: block;

								ul.sub-menu {
									margin: 0 0 0 10px;

									@include media-breakpoint-down(md) {
										margin-left: 15px;
									}
								}
							}
						}
					}
				}

				&.menu-item-has-children {
					> a {
						position: relative;

						@include media-breakpoint-down(md) {
							padding-right: 40px;
						}

						&:after {
							content: "\f078";
							font-family: "FontAwesome";
							font-weight: normal;
							margin-left: 0.5em;
							position: relative;
							top: -1px;

							@include media-breakpoint-down(md) {
								display: none;
							}
						}
					}
				}

				&:hover {
					ul.sub-menu {
						display: block;
					}

					> a {
						background-color: $white;
						text-shadow: none;
						color: saturate($secondary-color, 25);
					}
				}
			}

			&.open {
				@include media-breakpoint-down(md) {
					display: block;
				}
			}
		}

		&.open {
			ul.menu {
				@include media-breakpoint-down(md) {
					opacity: 1;
					visibility: visible;
				}
			}

			a.mobile-close-button {
				@include media-breakpoint-down(md) {
					display: block;
				}
			}
		}
	}

	aside.contact-icons {
		$spacing: 15px;
		border-left: 1px solid $white;
		padding: 10px 0;
		padding-left: $spacing;
		margin-right: -($spacing);

		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}

		*,
		& {
			vertical-align: middle;
		}

		a {
			display: inline-block;
			position: relative;
			top: 1px;
			padding: 0 $spacing;

			@include media-breakpoint-down(sm) {
				padding: 0 5px;
			}

			i {
				line-height: 1;
				display: block;
			}

			svg {
				width: 20px;

				path {
					fill: $white;
				}
			}

			&:hover {
				svg {
					path {
						fill: $purple;
					}
				}
			}
		}
	}
}
