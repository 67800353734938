section.instagram-feed {
	text-align: center;
	background-color: #ffffff;

	.feed {
		border-bottom: 1rem solid $purple;

		.instaphoto {
			overflow: hidden;
			float: left;
			width: 25%;
			padding-bottom: 25%;
			position: relative;
			z-index: 1;
			margin: 0;

			a {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				transition: all 0.3s ease;
				background-size: cover;
				background-repeat: no-repeat;
			}

			&:hover {
				a {
					transform: scale(1.05);
					z-index: 2;
				}
			}

			@include media-breakpoint-down(md) {
				width: 25%;
				padding-bottom: 25%;

				&:nth-last-child(-n + 2) {
					display: none;
				}
			}

			@include media-breakpoint-down(sm) {
				width: 50%;
				padding-bottom: 50%;
			}
		}

		&.column-count-3 {
			.instaphoto {
				width: 33.333%;
				padding-bottom: 33.333%;
			}
		}

		&.column-count-4 {
			.instaphoto {
				width: 25%;
				padding-bottom: 25%;
			}
		}

		&.column-count-5 {
			.instaphoto {
				width: 20%;
				padding-bottom: 20%;
			}
		}

		&.column-count-6 {
			.instaphoto {
				width: 16.666%;
				padding-bottom: 16.666%;
			}
		}
	}
}
